














import { Vue, Component } from "vue-property-decorator";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import AppAlert from "@/components/Alert.vue";

@Component({
    components: {
        Navbar,
        AppAlert,
        Footer
    }
})
export default class App extends Vue {}
