import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import AuthModule from "@/store/modules/authmodule";
import { abilities } from "@/plugins/casl-vue/abilities";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/Home.vue")
    },
    /*{
        path: "/cms-admin",
        name: "login",
        component: () => import("@/views/Login.vue")
    },
    {
        path: "/cms-admin/panel",
        name: "adminPanel",
        component: () => import("@/views/AdminPanel.vue"),
        meta: {
            requiresLogin: true
        }
    },*/
    {
        path: "/about",
        name: "about",
        component: () => import("@/views/About.vue")
    },
    {
        path: "/projects",
        name: "projects",
        component: () => import("@/views/Projects.vue")
    },
    {
        path: "/activities",
        name: "activities",
        component: () => import("@/views/Activities.vue")
    },
    {
        path: "/sponsoring",
        name: "sponsoring",
        component: () => import("@/views/Sponsoring.vue")
    },
    {
        path: "/links",
        name: "links",
        component: () => import("@/views/Links.vue")
    },
    {
        path: "/imprint",
        name: "imprint",
        component: () => import("@/views/Imprint.vue")
    },
    {
        path: "/privacy-policy",
        name: "privacyPolicy",
        component: () => import("@/views/PrivacyPolicy.vue")
    },
    {
        path: "*",
        redirect: { name: "home" }
    }
];

//old
/*const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: function(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        if (to.hash) {
            return {
                selector: to.hash,
                behavior: "smooth"
            };
        }

        return { x: 0, y: 0 };
    }
});
*/

const router = new VueRouter({
    routes
});

router.beforeEach(async (to, from, next) => {
    if (
        to.matched.some(
            record => record.meta.requiresLogin && !AuthModule.isAuthenticated
        )
    ) {
        next({ name: "login", query: { nextUrl: to.fullPath } });
    } else if (
        to.matched.some(
            record =>
                record.meta.requiredPermission &&
                record.meta.requiredPermission.action &&
                record.meta.requiredPermission.subject &&
                !abilities.can(
                    record.meta.requiredPermission.action,
                    record.meta.requiredPermission.subject
                )
        )
    ) {
        next(false);
    } else {
        next();
    }
});

export default router;
