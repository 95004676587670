















import AlertModule from "@/store/modules/alertmodule";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Alert extends Vue {
    get alerts() {
        return AlertModule.alerts;
    }
}
