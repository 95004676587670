import axios from "axios";
import i18n from "@/plugins/i18n";

// Exports a new axios instance with the API_URL variable of the .env file as baseURL
export default axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    headers: {
        common: {
            "Accept-Language": i18n.locale
        }
    }
});

// Export all types
export * from "./types";
