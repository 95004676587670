import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './plugins/i18n';

import vuetify from './plugins/vuetify';
import '@mdi/font/css/materialdesignicons.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({ 
    once: true,
    duration: 1000
});

Vue.config.productionTip = false;

new Vue({
    router,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app');

